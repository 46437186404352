<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Nagar Palika</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
        </div>
    </div>
    <div class="custom-ultima-datatable">
            <DataTable :value="nagarpalikaList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit" paginator :totalRecords="totalRecords" :rows="30" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading" >
            <template #empty>No Records found.</template>
            <template #loading>Loading data. Please wait...</template>
            <Column field="id" header="Sr." headerStyle="width: 5%" bodyStyle="width: 5%">
                <template #body="{ data }">
                    {{ data.id + 1 }}
                </template>
            </Column>
            <Column field="Name" header="Nagar Palika Name" headerStyle="width: 95%" bodyStyle="width: 95%">
                <template #body="{ data }">
                    <div>{{ data.label ? data.label : "N/A" }}</div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script>

export default {
    data() {
        return {
            nagarpalikaList: [
                {
                    id: 0,
                    label: "Ahmednagar Municipal Corporation, Maharashtra",
                },
                {
                    id: 1,
                    label: "Amravati Municipal Corporation",
                },
                {
                    id: 2,
                    label: "Bhiwandi Municipal Corporation",
                },
                {
                    id: 3,
                    label: "Chandrapur Municipal Corporation",
                },
                {
                    id: 4,
                    label: "Chhatrapati Sambhajinagar Municipal Corporation",
                },
                {
                    id: 5,
                    label: "Greater Mumbai Municipal Corporation",
                },
                {
                    id: 6,
                    label: "Jalgaon Municipal Corporation",
                },
                {
                    id: 7,
                    label: "Kalyan Dombivli Municipal Corporation, Maharashtra",
                },
                {
                    id: 8,
                    label: "Kolhapur Municipal Corporation, Maharashtra",
                },
                {
                    id: 9,
                    label: "Maharashtra State Veterinary Council",
                },
                {
                    id: 10,
                    label: "Mira Bhayander Municipal Corporation (MBMC), Maharashtra",
                },
                {
                    id: 11,
                    label: "Municipal Corporation of Greater Mumbai (MCGM)",
                },
                {
                    id: 12,
                    label: "Nagpur Municipal Corporation, Maharashtra",
                },
                {
                    id: 13,
                    label: "Nanded-Waghala Municipal Corporation",
                },
                {
                    id: 14,
                    label: "Nashik Municipal Corporation (NMC), Maharashtra",
                },
                {
                    id: 15,
                    label: "Navi Mumbai Municipal Corporation, Maharashtra",
                },
                {
                    id: 16,
                    label: "Panvel Municipal Corporation",
                },
                {
                    id: 17,
                    label: "Pimpri Chinchwad Municipal Corporation (PCMC), Maharashtra",
                },
                {
                    id: 18,
                    label: "Pune Municipal Corporation, Maharashtra",
                },
                {
                    id: 19,
                    label: "Sangli Miraj and Kupwad City Municipal Corporation, Maharashtra",
                },
                {
                    id: 20,
                    label: "Solapur Municipal Corporation, Maharashtra",
                },
                {
                    id: 21,
                    label: "Ulhasnagar municipal Corporation, Maharashtra",
                },
                {
                    id: 22,
                    label: "Vasai-Virar Municipal Corporation",
                },
            ],
            totalRecords: 23,
        };
    },
};
</script>